import styled from 'styled-components';

const Excerpt = styled.aside`
  display: flex;
  align-items: center;
  width: ${({ pagetype }) => (pagetype === 'post' ? '80%' : '90%')};
  margin: ${({ pagetype }) => (pagetype === 'post' ? '5rem 0' : 0)};
  padding: ${({ pagetype }) => (pagetype === 'post' ? '2rem' : '0 0 0 2rem')};
  font-size: 15.5px;
  font-style: italic;
  line-height: 1.4;
  letter-spacing: 1.1px;
  border-radius: ${({ pagetype }) =>
    pagetype === 'post' ? '0 2rem 2rem 0' : '0'};
  border-left: 5px solid ${({ theme }) => theme.color.active};
  background: ${({ bgcolor }) => (bgcolor ? bgcolor : 'none')};

  ${({ theme: { mq } }) => mq.tablet} {
    font-size: 14px;
    width: ${({ pagetype }) => (pagetype === 'post' ? '100%' : '90%')};
  }
`;

export default Excerpt;
