import { graphql, useStaticQuery } from 'gatsby';

const useImg = () => {
  const query = useStaticQuery(graphql`
    {
      allFile(
        filter: { extension: { regex: "/(jpg)|(jpeg)|(png)/" } }
        sort: { order: ASC, fields: base }
      ) {
        images: edges {
          node {
            absolutePath
            childImageSharp {
              fluid(maxWidth: 700) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  return {
    defaultImg: query.allFile.images[1].node.childImageSharp.fluid,
    authorPhoto: query.allFile.images[2].node.childImageSharp.fluid,
    algolia: query.allFile.images[3].node.childImageSharp.fluid,
  };
};

export { useImg };
